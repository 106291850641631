<template>
  <div class=''>
    <div style="font-size: 35px">预约远程诊疗</div>
    <el-divider></el-divider>
    <!--    搜索框目前绑定的是医生姓名关键词，考虑增加/替换成科室关键词-->
    <el-input
        style="width: 30%"
        placeholder="请输入关键词查询"
        v-model="search"
        clearable>
    </el-input>


    <el-table :data="reshapedData.filter(data => !search || data.realName.toLowerCase().includes(search.toLowerCase()))">
      <el-table-column label="医生姓名" prop="realName"></el-table-column>
      <el-table-column label="称号" prop="title"></el-table-column>
      <el-table-column label="简介" prop="introduction">
        <template v-slot="scope10">
          <el-popover
              title="医生简介"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope10.row.introduction}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="科室" prop="department"></el-table-column>

      <el-table-column label="可预约时间" prop="appointDay">
        <template v-slot="scope11">
          <el-select v-model="scope11.row.selectedDay" v-bind:valueDate = scope11.row.selectedDay placeholder="请选择">
<!--            <el-option-->
<!--                v-for="item in scope11.row.avaDay"-->
<!--                :key="item"-->
<!--                :label="item"-->
<!--                :value="item">-->
              <el-option
                  v-for="item in okDay"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>

      <el-table-column label="症状备注" prop="Remark" width="200%">
        <template v-slot="scope12">
          <el-input
              type="textarea"
              :rows="1"
              placeholder="请简要描述症状"
              v-model="scope12.row.textarea" v-bind:valueText=scope12.row.textarea>
          </el-input>
        </template>
      </el-table-column>

      <el-table-column label="提交预约" prop="Submit">
        <!--        考虑到效率问题，应该限制单个用户的预约总量，这里还没有写，考虑读取用户的预约记录并限制已有多条预约未完成的用户提交新预约-->
        <template v-slot="scope13">
          <span v-if="appointHisString.length<2">
          <el-button type="primary"
                     @click="appointmentSubmit(scope13.row)">提交</el-button>
            </span>
          <span v-else>
            <el-button type="primary"
                       @click="open2()">提交</el-button>
          </span>
        </template>
      </el-table-column>


    </el-table>



  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Vue from 'vue'



export default {
//import引入的组件需要注入到对象中才能使用

  components: {},
  data() {
//这里存放数据
    return {
      search: '',
      valueText:'',
      valueDate:'',
      test001: [],

      userId: this.$store.state.women_baby.uid,
      userType: '个人',
      uname: this.$store.state.women_baby.username,

      timeTableGet:[],
      timeTableString:[],
      timeTableString1:[],
      timeTableString2:[],
      reshapedData: [],
      selectedData: [],
      appointHisString: [],
      okDay:[],
    };
  },
//监听属性 类似于data概念
  computed: {},
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {
    generateTime(){
      // let nowday = new Date();
      // let thisday = new Date(nowday)
      // for(let i = 1;i<8;i++){
      //   thisday.setDate(nowday.getDate()+1)
      //   thisday.setMonth(nowday.getMonth())
      //   console.log("哪一天")
      //   console.log(thisday)
      //   if(thisday.getDay()!="Sat"||thisday.getDay()!="Sun"){
      //     this.okDay.push(thisday)
      //   }
      // }
      let theday = new Date()

      const valueArray = []
      for(let i = 0;i<7;i++){
        let dayArray = []
        theday.setDate(theday.getDate()+1)
        console.log("哪一天")
        console.log(theday.getDay())
        if(theday.getDay()!='6' && theday.getDay()!='0'){
          // dayArray.push(theday.toLocaleDateString())
          // dayArray.push(theday.toLocaleDateString())
          let nowDay = theday.getDate();
          let nowMonth = theday.getMonth()+1;
          let nowYear = theday.getFullYear();
          // dayArray.push(nowYear+"-"+nowMonth+"-"+nowDay+"上午")
          // valueArray.push(nowYear+"-"+nowMonth+"-"+nowDay+" "+"12:00")
          // dayArray.push(nowYear+"-"+nowMonth+"-"+nowDay+"下午")
          // valueArray.push(nowYear+"-"+nowMonth+"-"+nowDay+" "+"18:00")
          dayArray.value = nowYear+"-"+nowMonth+"-"+nowDay+" "+"12:00"
          dayArray.label = nowYear+"-"+nowMonth+"-"+nowDay+"上午"
          console.log("看看上午")
          console.log(dayArray)
          valueArray.push(dayArray)
          dayArray.value = nowYear+"-"+nowMonth+"-"+nowDay+" "+"18:00"
          dayArray.label = nowYear+"-"+nowMonth+"-"+nowDay+"下午"
          valueArray.push(dayArray)
          console.log("看看下午")
          console.log(dayArray)
        }
        console.log("看看所有")
        console.log(valueArray)
      }

      this.okDay = valueArray
      // this.okDay.value = dayArray
      // this.okDay.label = valueArray
      console.log("看看这周时间")
      console.log(this.okDay)

      // let aDay = new Date()
      // const finalList = []
      // for(let item of dayArray ){
      //   aDay.setDate(item)
      //   let nowDay = aDay.getDate();
      //   let nowMonth = aDay.getMonth()+1;
      //   let nowYear = aDay.getFullYear();
      //   finalList.push(nowYear+"-"+nowMonth+"-"+nowDay+"上午")
      //   finalList.push(nowYear+"-"+nowMonth+"-"+nowDay+"下午")
      // }
      // console.log("看看这组时间")
      // console.log(finalList)
    },

    unique(arr){
      return Array.from(new Set(arr))
    },

    open2() {
      this.$notify({
        title: '警告',
        message: '您已有两条未完成预约，暂时不可提交新的预约',
        type: 'warning'
      });
    },
    getDocInfo() {
      this.$http({
        url: this.$http.adornUrl('/personal/remoteDiagnosis/listCanOrder'),
        /*url: 'http://47.106.217.34:8445/heart-watchdog/doctor/canorderlist/listDetail',*/
        method: 'get',
      }).then(({data}) => {
        if (data && data.code === 0) {
          console.log("我人傻了")
          console.log(data)

          for(let item of data.list){
            this.timeTableString = item
            Vue.set(this.timeTableString,'selectedDay','')
            Vue.set(this.timeTableString,'avaDay',[])
            Vue.set(this.timeTableString,'textarea','')

            this.timeTableString.canOrderDate = JSON.parse(this.timeTableString.canOrderDate)
            this.timeTableString.cantOrderDate = this.timeTableString.cantOrderDate.replace('[','["')
            this.timeTableString.cantOrderDate = this.timeTableString.cantOrderDate.replace(']','"]')
            this.timeTableString.cantOrderDate = this.timeTableString.cantOrderDate.replace(',','","')
            this.timeTableString.cantOrderDate = JSON.parse(this.timeTableString.cantOrderDate)

            for (const item of this.timeTableString.canOrderDate) {
                /*for (const Item in item.appointTime) {*/
                this.timeTableString.avaDay.push(item.appointDay + " " + item.appointTime.time1)
                this.timeTableString.avaDay.push(item.appointDay + " " + item.appointTime.time2)
                this.timeTableString.avaDay.push(item.appointDay + " " + item.appointTime.time3)
              }

            // for(let i in this.timeTableString.avaDay){
            //     for(let t in this.timeTableString.cantOrderDate){
            //       if(new Date(this.timeTableString.avaDay[i]).getTime()==new Date(this.timeTableString.cantOrderDate[t]).getTime()){
            //         this.timeTableString.avaDay.splice(i,1)
            //       }
            //     }
            //   }

            var LT = this.timeTableString.avaDay.length
            console.log("瞅一眼长度")
            console.log(LT)
            var z = 0
            while (z<=LT){
              for(let t in this.timeTableString.cantOrderDate){
                if(new Date(this.timeTableString.avaDay[z]).getTime()==new Date(this.timeTableString.cantOrderDate[t]).getTime()){
                  this.timeTableString.avaDay.splice(z,1)
                  z=z-1
                  LT=LT-1
                }
              }
              z=z+1
            }

            let today = new Date()
            let theDay = new Date(today)
            theDay.setDate(today.getDate()+2)
            theDay.setMonth(theDay.getMonth())
            let nowDay = theDay.getDate();
            let nowMonth = theDay.getMonth()+1;
            let nowYear = theDay.getFullYear();
            var thisTime = nowYear + "-" + nowMonth + "-" + nowDay

            console.log("瞅一眼时间")
            console.log(new Date(thisTime))

            var TL = this.timeTableString.avaDay.length
            console.log("瞅一眼长度")
            console.log(TL)

            var k = 0
            while (k<=TL) {
              var result = new Date(this.timeTableString.avaDay[k]).getTime()<new Date(thisTime).getTime()
              if(result){
                this.timeTableString.avaDay.splice(k,1)
                k = k-1
                TL = TL -1
              }
              k = k+1
            }

            // for(let k in this.timeTableString.avaDay){
            //   console.log("再瞅一眼时间")
            //   console.log(new Date(this.timeTableString.avaDay[k]).getTime())
            //   var result = new Date(this.timeTableString.avaDay[k]).getTime()<new Date(thisTime).getTime()
            //   console.log("瞅一眼对比")
            //   console.log(result)
            //   if(result){
            //     this.timeTableString.avaDay.splice(k,1)
            //   }
            //   // if(new Date(this.timeTableString.avaDay[i]).getMonth()<new Date(thisTime).getMonth()){
            //   //   this.timeTableString.avaDay.splice(i,1)
            //   // }
            //   // else if(new Date(this.timeTableString.avaDay[i]).getDate()<new Date(thisTime).getDate()){
            //   //   this.timeTableString.avaDay.splice(i,1)
            //   // }
            // }

            console.log("挨个看看")
            console.log(this.timeTableString)

            this.reshapedData.push(this.timeTableString)

            // this.timeTableString1 = JSON.stringify(this.timeTableString)
            // this.timeTableString1 = this.timeTableString1.slice(1)
            // this.timeTableString1 = this.timeTableString1.slice(0,this.timeTableString1.length-1)
            // this.timeTableString2 = JSON.parse(this.timeTableString1)
            // Vue.set(this.timeTableString2,'selectedDay','')
            // /*Vue.set(this.timeTableString2,'selectedTime','')*/
            // Vue.set(this.timeTableString2,'avaDay',[])
            // Vue.set(this.timeTableString2,'textarea','')
            //
            // this.timeTableString2.canOrderDate = JSON.parse(this.timeTableString2.canOrderDate)
            // this.timeTableString2.cantOrderDate = this.timeTableString2.cantOrderDate.replace('[','["')
            // this.timeTableString2.cantOrderDate = this.timeTableString2.cantOrderDate.replace(']','"]')
            // this.timeTableString2.cantOrderDate = this.timeTableString2.cantOrderDate.replace(',','","')
            // this.timeTableString2.cantOrderDate = JSON.parse(this.timeTableString2.cantOrderDate)
            //
            // for (const item of this.timeTableString2.canOrderDate) {
            //   /*for (const Item in item.appointTime) {*/
            //   this.timeTableString2.avaDay.push(item.appointDay + " " + item.appointTime.time1)
            //   this.timeTableString2.avaDay.push(item.appointDay + " " + item.appointTime.time2)
            //   this.timeTableString2.avaDay.push(item.appointDay + " " + item.appointTime.time3)
            // }
            //
            // for(let i in this.timeTableString2.avaDay){
            //   for(let t in this.timeTableString2.cantOrderDate){
            //     if(new Date(this.timeTableString2.avaDay[i]).getTime()==new Date(this.timeTableString2.cantOrderDate[t]).getTime()){
            //       this.timeTableString2.avaDay.splice(i,1)
            //     }
            //   }
            // }
            //
            // this.reshapedData.push(this.timeTableString2)

            }
          }



          // this.timeTableString = data.list
          // this.timeTableString1 = JSON.stringify(this.timeTableString)
          // this.timeTableString1 = this.timeTableString1.slice(1)
          // this.timeTableString1 = this.timeTableString1.slice(0,this.timeTableString1.length-1)
          // this.timeTableString2 = JSON.parse(this.timeTableString1)
          // Vue.set(this.timeTableString2,'selectedDay','')
          // /*Vue.set(this.timeTableString2,'selectedTime','')*/
          // Vue.set(this.timeTableString2,'avaDay',[])
          // Vue.set(this.timeTableString2,'textarea','')

          // this.timeTableString2.canOrderDate = JSON.parse(this.timeTableString2.canOrderDate)
          // this.timeTableString2.cantOrderDate = this.timeTableString2.cantOrderDate.replace('[','["')
          // this.timeTableString2.cantOrderDate = this.timeTableString2.cantOrderDate.replace(']','"]')
          // this.timeTableString2.cantOrderDate = this.timeTableString2.cantOrderDate.replace(',','","')
          // this.timeTableString2.cantOrderDate = JSON.parse(this.timeTableString2.cantOrderDate)

          // for (const item of this.timeTableString2.canOrderDate) {
          //   /*for (const Item in item.appointTime) {*/
          //   this.timeTableString2.avaDay.push(item.appointDay + " " + item.appointTime.time1)
          //   this.timeTableString2.avaDay.push(item.appointDay + " " + item.appointTime.time2)
          //   this.timeTableString2.avaDay.push(item.appointDay + " " + item.appointTime.time3)
          // }

          // for(let i in this.timeTableString2.avaDay){
          //   for(let t in this.timeTableString2.cantOrderDate){
          //     if(new Date(this.timeTableString2.avaDay[i]).getTime()==new Date(this.timeTableString2.cantOrderDate[t]).getTime()){
          //       this.timeTableString2.avaDay.splice(i,1)
          //     }
          //   }
          // }

          /*!!!这个地方使用for循环是为了把对象转成数组，el-table貌似识别不了数组！！！*/
          /*！！！只有一条数据的情况下for报错，回头记得测试多条数据的时候能不能正常使用！！！*/
          /*！！！千万千万别忘了！！！*/
          /*！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！*/
          /*！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！*/
          /*！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！*/
          // for(let i in this.timeTableString2) {
          //   this.reshapedData.push(this.timeTableString2)
          // }

          // console.log("看看获取到的东西")
          // console.log(data)
        else {
          this.$message.error(data.msg)
        }
      })
    },

    appointmentSubmit(Obj){
      this.$http({
        url: this.$http.adornUrl('/personal/remoteDiagnosis/createOrder'),
        method: 'post',
        data: this.$http.adornData({
          userId: this.userId,
          userType: this.userType,
          uname: this.uname,
          docId: Obj.docId,
          symptoms: Obj.textarea,
          dateTime: Obj.selectedDay,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看提交后返回的东西")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    appointHistoryGet(){
      this.$http({
        url: this.$http.adornUrl('/personal/remoteDiagnosis/listRecordByUid'),
        method: 'get',
        params: this.$http.adornParams({ userId: this.userId , userType: this.userType })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看预约数据")
          console.log(data)
          this.appointHisString = data.recordOrderList
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },


    dayvalue(Alist){
      let arr = []
      for(var i in Alist){
        arr.push(i.appointDay)
      }
      return arr
    },
  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getDocInfo();
    this.appointHistoryGet();
    /*Vue.set(this.reshapedData,'selectedDay',"123")*/
    /*this.reshapedData.push({daySelected: ""});*/

  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.generateTime()
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>
